.Description {
  // padding: 16px;
  width: 100%;
  // margin-right: 24px;

  &_descriptionAnchor {
    // background-color: red;
    display: flex;
    flex-direction: row;
  }

  &_descriptionShowText {
    font-weight: 600;
    color: #2278ff;
  }

  &_Top {
    margin-top: 12px;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid #e5e5e5;
  }

  &_Singleplan {
    padding: 16px 0;
    font-weight: 600;
    border-bottom: 1px solid #e5e5e5;
    color: #3c4852;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &_PricePlan {
    margin-top: 16px;
  }

  &_Text {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  &_Head {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3c4852;
  }

  &_Text {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #7a8b94;
  }

  &_Price {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3c4852;
  }

  &_Files {
    padding: 16px;
    width: 100%;

    &_File {
      display: flex;
      align-items: center;
    }
  }

  &_ButtonContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    z-index: 10;

    &_Button {
      padding: 12px;
      background-color: #2278ff;
      border-radius: 4px;
      color: white;
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;

      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
