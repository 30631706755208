.createCoupon {
  padding: 16px 16px 32px 16px;
  min-height: 100%;
  max-height: fit-content;
  background-color: white;
  padding-bottom: 150px;

  &_amountContainer {
    flex-direction: row;
    display: flex;
  }

  &_inputContainer {
    margin-right: 32px;
  }

  &_visibilityContainer {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 1px;
  }

  &_label {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0a1629;
  }

  &_ButtonContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    z-index: 10;

    &_Button {
      padding: 12px;
      background-color: #2278ff;
      border-radius: 4px;
      color: white;
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_img_icon {
      color: #32a22a;
    }
  }

  &_discountAmountDropDownContainer {
    flex-direction: row;
    display: flex;
    background-color: white;
    border-color: #7a8b94;
    border-width: 1;
    flex: 1;
  }

  &_textInput {
    width: 100%;
  }

  &_allPlansSwitchContainer {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  &_SinglePlan {
    width: 100%;
    margin: 13px 0 0 0;
    padding: 16px;
    border: 0.5px solid rgb(144, 144, 144);
    border-radius: 8px 8px 8px 8px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: white;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    &_Right {
      display: flex;
      align-items: center;
      // justify-content: ;
    }

    &_Offer {
      background: linear-gradient(147.14deg, #00cfde 6.95%, #05a660 93.05%);
      border-radius: 10px 10px 0px 0px;
      color: white;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      padding: 4px 23px;
      height: 24px;

      z-index: 1;
      position: absolute;
      left: -2px;
      top: -26px;
    }

    &_Period {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #7a8b94;
    }

    &_Price {
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      text-decoration-line: line-through;
      color: #7a8b94;
      margin-right: 10px;
    }

    &_FinalPrice {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #2278ff;
    }
  }
}
