.failure {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 112px;
  padding: 0 24px;
  text-align: center;

  &_orderid {
    color: #2278ff;
    font-weight: 400;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &_title {
    padding: 16px 0 8px 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #3c4852;
  }

  &_text {
    padding: 0 0 4px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7a8b94;
  }

  &_button {
    padding: 20px 0;
    width: 100%;
  }
}

.Details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  padding-bottom: 100px;

  &_ButtonContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    z-index: 10;

    &_Button {
      padding: 12px;
      background-color: #2278ff;
      border-radius: 4px;
      color: white;
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_img_icon {
      color: #32a22a;
    }
  }

  &_btn_img {
    display: flex;
  }
}
