.SingleStatement {
  margin: 4px 0;
  padding: 16px;
  background-color: white;
  &_Name {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3c4852;
  }

  &_Footer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_Left {
      display: flex;
      align-items: center;

      &_Date {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.6);
      }

      &_Badge {
        margin-left: 12px;
        padding: 4px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
      }
    }

    &_Right {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #3c4852;
    }
  }
}
