.otp {
  display: flex;
  flex-direction: column;
  padding: 16px;

  &_numberWarning {
    font-weight: 400;
    font-size: 12px;
    color: gray;
    margin-top: 4px;
    line-height: 15px;
  }

  &_numberWarningContainer {
    margin-top: 18px;
  }

  &_numberWarningHeading {
    text-align: center;
    font-weight: 400;
    margin-top: 20px;
  }

  &_text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
  }

  &_number {
    position: relative;
    display: flex;
    border: none;
    background: #f7f7f7;
    border-radius: 4px;
    margin-top: 8px;

    &-code {
      padding: 14px 16px;
      color: #7a8b94;
      border-right: 1px solid #c4cdd5;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    &-input {
      padding: 14px 16px;
      border: none;
      background: #f7f7f7;
      outline: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #7a8b94;
    }

    &-button {
      padding: 14px 13px 14px 16px;
      border: none;
      position: absolute;
      right: 0px;
      top: 0px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #3c4852;
      background: none;
    }
  }

  &_content {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #3c4852;
    padding: 16px 0px 8px 0;
  }

  &_field {
    display: flex;
  }

  &_waiting {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    padding: 16px 0 0 0;
    cursor: default;
  }

  &_inputStyle {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    outline: none;
    text-align: center;
  }

  &_footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    padding: 0 16px 16px;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.24);

    color: rgba(0, 0, 0, 0.6);

    &-container {
      font-style: normal;
      font-weight: 450;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 0 16px 0;
    }

    &-img {
      padding-right: 8px;
    }

    &-button {
    }
  }
}
.WA_Container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-top: 20px;
  &_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3c4852;
    margin-left: 5px;
    align-content: center;
    justify-content: center;
  }
  &_img {
    width: 16px;
    height: 16px;
    margin-left: 2px;
  }
}
