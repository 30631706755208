@import "../../scss/main";

.bankDetails {
  padding: 12px 0px 80px 0px;
  min-height: 100%;
  max-height: fit-content;
  background-color: white;
  &__form {
    padding: 8px 16px;
  }
  &__footer {
    border-top: 1px solid $color-hr-grey;
    width: 100%;
    padding: 16px;
    position: fixed;
    background-color: white;
    z-index: 100;
    bottom: 0;
    left: 0;
  }
}
.emailText {
  width: 328px;
  height: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7d8592;
}
.label {
  @include font-small;
  color: $color-text-black;
}

@media only screen and (max-height: 600px) {
  .bankDetails {
    &__footer {
      display: none;
    }
  }
}
