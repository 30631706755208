.custom-input {
  display: inline-block;

  cursor: pointer;
  width: 100%;

  &__text {
    padding: 12px;
    background: #ffffff;
    border: 1px solid #d8e0f0;
    border-radius: 14px;
    font-size: 14px;
    display: inline-block;
    width: 100%;
    height: 50px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  span {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.DayPicker * {
  outline: none;
}

.uparrow {
  transform: rotate(180deg);
}

.downarrow {
  transform: rotate(0deg);
}
