.ExportReport {
  padding: 16px 16px 32px 16px;
  // min-height: 100%;
  height: 100%;
  max-height: fit-content;
  background-color: white;
  padding-bottom: 150px;

  &_form {
    margin-top: 13px;
  }

  &_ButtonContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    z-index: 10;

    &_Button {
      padding: 12px;
      background-color: #2278ff;
      border-radius: 4px;
      color: white;
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_successPopup {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &_successHeading {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }

  &_successText {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #7d8592;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  &_successBTNContainer {
    margin-right: 84px;
    margin-left: 84px;
  }
}
