@import "../../scss/main";

.inputLabel2 {
  @include font-small;
  color: $color-text-black;
}
.inputContainer2 {
  // margin: 20px 0 16px;
  @include flex-align(center);
  border: 1px solid $color-stroke-grey;
  padding: 10px 15px;
  border-radius: 10px;
  // background-color: green;
  width: 320px;

  &__icon1 {
    @include flex-align(center);
    padding: 2px 12px;
  }

  input,
  textarea {
    width: 100%;
    @include font-small;
    color: $color-text-black;
    border: none;
    outline: none;
    resize: none;
    font-family: Roboto;
    @include input-noBorder;
    &::placeholder {
      color: $color-text-grey;
      font-family: Roboto;
    }
    user-select: text !important;
  }
}
