.userDetails {
  display: flex;
  flex-direction: column;
  padding: 16px;
  &_heading {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &-image {
      margin: 16px 16px 16px 0;
    }
    &-text {
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      color: #333333;
    }
  }
  &_mobile {
    position: relative;
    &-countryCode {
      position: absolute;
      border-radius: 14px 0 0 14px;
      left: 0px;
      width: 66px;
      background: #ffffff;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #333333;
      top: 28px;
      height: 52px;
      padding: 13px 16px;
      border-right: 1px solid #c4c4c4;
      border-left: 1px solid #f2f2f2;
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
    }
  }
  &_footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    padding: 0 16px 16px;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.24);

    color: rgba(0, 0, 0, 0.6);
    &-container {
      font-style: normal;
      font-weight: 450;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 0 16px 0;
    }
    &-img {
      padding-right: 8px;
    }
    &-button {
    }
  }
  &_description {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7d8592;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &_loadingImg {
    width: 102px;
    height: 91px;
    align-self: center;
  }
  &_desc {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0a1629;
    margin-top: 20px;
  }
  &_apiFail {
    margin-top: 38px;
    display: flex;
    flex-direction: column;
    &_head {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #0a1629;
    }
    &_knot {
      // position: absolute;
      width: 19px;
      height: 4px;
      left: 0px;
      top: 0px;
      background: #2278ff;
      border-radius: 50px;
    }
    &_points {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7d8592;
      margin-top: 10px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .userDetails {
    &_footer {
      box-shadow: none;
      position: initial;
      margin-top: 100px;
    }
  }
}
