@import "../../scss/main";

.container {
  &_earningsContainer {
    background-color: white;
    margin-top: 8px;
    padding-top: 1px;
    height: calc(100% - 60px);
  }

  &_tagContainer {
    flex-direction: row;
    display: flex;
  }

  &_coupon {
    font-weight: 500;
    font-size: 12px;
    color: #2278ff;
    background-color: #2278ff1a;
    padding: 5px;
    display: inline;
  }

  &_earningType {
    background-color: #e4e6e8;
    display: inline-block;
    margin-top: 16px;
    border-radius: 28px;
    justify-content: space-between;
    padding-right: 6px;
    padding-left: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 16px;
  }

  &_typeName {
    display: inline-block;
    margin-right: 2px;
    margin-left: 2px;
    padding: 10px;
    border-radius: 28px;
    font-weight: 500;
    font-size: 12px;
  }

  &_selectedType {
    display: inline-block;
    margin-right: 2px;
    margin-left: 2px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 28px;
    background-color: #2278ff;
    color: white;
    font-weight: 500;
    font-size: 12px;
  }

  &_totalEarningsContainer {
    display: flex;
    flex-direction: row;
    margin-left: 17px;
    margin-right: 20px;
    margin-top: 16px;
    justify-content: space-between;
  }

  &_totalEarningsTitle {
    color: #7d8592;
    font-size: 14px;
    font-weight: 500;
  }

  &_totalEarningsValue {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
  }

  &_searchOuterBox {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  &_searchText {
    margin-right: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #2278ff;
  }

  &_totalPurchasesHeading {
    font-weight: 600;
    font-size: 14px;
    align-items: center;
    color: #000000;
    // margin-top: 19px;
    margin-left: 15px;
    // margin-bottom: 11px;
  }

  &_listContainer {
    background-color: white;
  }

  &_itemContainer {
    background-color: white;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 4px;

    &_left {
    }

    &_right {
    }
  }

  &_name {
    font-weight: 600;
    font-size: 14px;
    margin-top: 16px;
    margin-bottom: 4px;
    color: #3c4852;
  }

  &_date {
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &_dateText {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-top: 19px;
    color: #7d8592;
  }

  &_mobile {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.6);
  }

  &_amount {
    font-size: 16px;
    margin-bottom: 4px;
    text-align: right;
    font-weight: 500;
    color: #3c4852;
    margin-top: 18px;
  }

  &_details {
    font-weight: 700;
    font-size: 10px;
    color: #2278ff;
    margin-top: 10px;
    margin-top: 2px;
    text-align: right;
  }

  &_detailsContainer {
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    margin-top: 13px;
    justify-content: space-between;
    margin-right: 16px;
  }

  &_detailsText {
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 4px;
  }

  &_detailsAmount {
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 4px;
  }

  &_lifetime {
    font-weight: 500;
    font-size: 12px;
    color: #52b062;
    background-color: rgba(82, 176, 98, 0.1);
    padding: 5px;
    display: inline;
  }

  &_subscription {
    font-weight: 500;
    font-size: 12px;
    padding: 5px;
    color: #ffad3b;
    background-color: rgba(255, 173, 59, 0.1);
    display: inline;
    margin-right: 8px;
  }

  &_notJoined {
    font-weight: 500;
    font-size: 12px;
    color: #f65160;
    background-color: rgba(246, 81, 96, 0.1);
    padding: 5px;
    display: inline;
    margin-right: 8px;
  }

  &_joined {
    font-weight: 500;
    font-size: 12px;
    color: #52b062;
    background-color: rgba(82, 176, 98, 0.1);
    padding: 5px;
    display: inline;
    margin-right: 8px;
  }

  &_img {
    margin-right: 3px;
  }
}
