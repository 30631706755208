.Details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  padding-bottom: 100px;
  background-color: #ffffff;

  &_coAdminState0Container {
    flex-direction: row;
    display: flex;
    border: 1px solid #eaf2ff;
    padding: 12px;
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
  }

  &_wantToAddAdmin {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3c4852;
  }

  &_AddBTNContainer {
    border: 1px solid #2278ff;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    width: 72px;
    height: 29px;
  }

  &_blueAdd {
    height: 10.6px;
    width: 10.6px;
    left: 2.6px;
    top: 2.6px;
    border-radius: 0px;
  }

  &_addTxt {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #2278ff;
    margin-left: 7px;
  }

  &_btSheetListItem {
    flex-direction: row;
    display: flex;
    margin-top: 22px;
  }

  &_gotItBTNContainer {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &_btSheetStepper {
    width: 20px;
    height: 20px;
  }

  &_stepperText {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #7d8592;
    margin-left: 8px;
  }

  &_UpperBlock {
    flex-direction: column;
    width: 100%;
    border-bottom: 1px dashed #a6a6a6;
    // padding-bottom: 20px;
    margin-bottom: 10px;
  }

  &_image {
    height: 100px;
    width: 100px;
    border-radius: 50px;
  }

  &_Top {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    // background-color: #05a660;
  }

  &_Badges {
    align-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &_Name {
    margin-top: 16px;
    margin-left: 20px;
    // margin-right: 250px;
    // background-color: #2278ff;
    width: 60%;

    &_txt {
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.87);
      overflow-wrap: break-word;
    }
  }

  &_Date {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.6);

    display: flex;
    align-items: center;
  }

  &_Pending {
    margin: 5px 0 20px 0;
    background-color: rgba(252, 124, 73, 0.1);
    color: rgba(255, 155, 14, 1);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 6px;
    border-radius: 4px;

    display: flex;
    align-items: flex-start;
  }

  &_createCouponContainer {
    background-color: #eaf2ff;
    display: flex;
    margin-right: 16px;
    margin-left: 16px;
    width: 100%;
    margin-top: 20px;
    flex-direction: row;
    padding: 12px;
  }

  &_couponHeader {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3c4852;
  }

  &_createCouponDescription {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7a8b94;
  }

  &_createContainer {
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
  }

  &_createBTN {
    background-color: #2278ff;
    border-radius: 4px;
    flex-direction: row;
    display: flex;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    justify-content: center;
    align-items: center;
  }

  &_createBTNImg {
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }

  &_createBtnText {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }

  &_ChannelCreated {
    margin: 5px 0 20px 0;
    background-color: rgba(50, 162, 42, 0.1);
    color: #32a22a;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 6px;
    border-radius: 4px;
    padding-top: 20;
    display: flex;
    // align-items: flex-start;
  }

  &_Planheading {
    width: 100%;
    text-align: left;
    margin-top: 30px;
    font-size: 14px;
    font-weight: bold;
  }

  &_SinglePlan {
    width: 100%;
    margin: 40px 0 0 0;
    padding: 20px;
    border: 2px solid rgb(144, 144, 144);
    border-radius: 0px 8px 8px 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    &_Right {
      display: flex;
      align-items: center;
      // justify-content: ;
    }

    &_Offer {
      background: linear-gradient(147.14deg, #00cfde 6.95%, #05a660 93.05%);
      border-radius: 10px 10px 0px 0px;
      color: white;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      padding: 4px 23px;
      height: 24px;

      z-index: 1;
      position: absolute;
      left: -2px;
      top: -26px;
    }

    &_Period {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #7a8b94;
    }

    &_Price {
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      text-decoration-line: line-through;
      color: #7a8b94;
      margin-right: 10px;
    }

    &_FinalPrice {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #2278ff;
    }
  }

  &_ButtonContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    z-index: 10;

    &_Button {
      padding: 12px;
      background-color: #2278ff;
      border-radius: 4px;
      color: white;
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_img_icon {
      color: #32a22a;
    }
  }

  &_btn_img {
    display: flex;
  }
  &_whatsApp_waitingBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    width: 100%;
    height: 73px;
    background: #dceaff;
    opacity: 0.8;
    border: 1px solid #2278ff;
    border-radius: 8px;
    margin-bottom: 10px;
    &_outer {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      // padding: 12px 10px 12px 10px;
      // background-color: #2278ff;
    }
    &_column {
      display: flex;
      flex-direction: column;
    }
    &_headText {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #0a1629;
    }
    &_description {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #0a1629;
      margin-top: 4px;
    }
    &_view {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #2278ff;
    }
  }
}
