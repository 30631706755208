.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 250px;
  text-align: center;
  padding: 0 24px;

  &_loaderHeading {
    // margin-top: 10px;
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    margin-left: 13px;
  }

  &_loaderDescription {
    font-size: 18px;
    font-weight: 300;
    margin-top: 10px;
  }
}
