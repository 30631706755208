@import "../../../../../scss/main";

.PlatformSelection {
  height: 231px;
  // background-color: antiquewhite;
  &__tapBlock {
    display: flex;
    justify-content: center;
    height: 20px;
    // background-color: aqua;
    &_tap {
      display: flex;
      align-self: center;
      width: 32px;
      height: 4px;
      background: #d8e0f0;
      border-radius: 4px;
    }
  }
  &_head {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0a1629;
    margin-top: 20px;
  }
  .elementContainer {
    // background-color: aqua;
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 20px;
    margin-top: 20px;
    &_telegramElement {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 12px;
      gap: 6px;
      width: 96px;
      height: 123px;
      background: #e4fbff;
      border-radius: 20px;
    }
    &_whatsappElement {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 12px;
      gap: 6px;

      width: 98px;
      height: 123px;
      margin-left: 20px;
      background: #ebffe3;
      border-radius: 20px;
    }
    &_img {
      width: 72px;
      height: 72px;
    }
    &_text {
      // font-family: 'Poppins';
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #0a1629;
    }
    &_beta {
      display: flex;
      flex-direction: row;
    }
  }
  &_Beta {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    gap: 10px;
    // position: absolute;
    width: 41px;
    height: 26px;
    left: 75px;
    top: 5px;
    background: #7d8592;
    border-radius: 11px;
    color: #ffffff;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-left: -20px;
    margin-top: 5px;
  }
}
