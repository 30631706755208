.LeadCapture {
  padding: 80px;
  margin-bottom: 50px;
  background: #eff5f9;
  border-radius: 16px;
  &_container {
    display: flex;
    flex-direction: row;
    padding: 30px;
  }
  &_leftBlock {
    display: flex;
    flex-direction: column;
    width: 40%;

    &_headTxt {
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 150%;
      color: #0a1629;
    }
    &_description {
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: #7d8592;
    }
  }
  &_rightBlock {
    width: 60%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    &_form {
      display: flex;
      flex-direction: row;

      &_formElement {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 10px;
      }
    }
  }
}
.formLabel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a1629;
}
.formLabel1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a1629;
  margin-top: 0px;
}
.ctaBlock {
  display: flex;
  justify-content: right;

  // background-color: aqua;
  &_CTAContainer {
    background-color: #2278ff;
    width: 250px;
    display: flex;
    justify-content: center;
    // flex: 1;
    flex-direction: row;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 8px;
    margin-top: 24px;
    margin-right: 10px;
    margin-left: 24px;
  }

  &_CTAIcon {
    height: 16px;
    width: 18px;
    margin-left: 10px;
  }

  &_joinText {
    font-size: 16px;
    font-weight: 600;
    color: white;
  }
}
.Thankyou {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  &_imgBlock {
    display: flex;
    justify-content: center;
  }
  &_img {
    width: 120px;
    height: 120px;
  }
  &_headText {
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;
    margin-top: 21px;
    color: #0a1629;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &_description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #7a8b94;
  }
}
.dropDownStyle {
  background-color: red;
  padding: 13px;
  margin-top: 10px;
  border-color: white;
  border-radius: 10px;
  color: #7d8592;
}
@media only screen and (max-width: 550px) {
  .LeadCapture {
    padding: 5px;
    &_container {
      display: flex;
      flex-direction: column;
      align-self: center;
      // padding:10px
    }
    &_leftBlock {
      width: 100%;
      &_headTxt {
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 150%;
        color: #0a1629;
      }
      &_description {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #7d8592;
      }
    }
    &_rightBlock {
      width: 100%;
      margin-left: 0px;
      margin-top: 20px;
      &_form {
        display: flex;
        flex-direction: column;

        &_formElement {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 0px;
        }
      }
    }
  }
  .Thankyou {
    &_img {
      width: 80px;
      height: 80px;
    }
    &_headText {
      font-size: 24px;
      line-height: 36px;
    }
    &_description {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .formLabel1 {
    margin-top: 20px;
  }
  .ctaBlock {
    &_CTAContainer {
      flex: 1;
      margin-right: 24px;
    }
  }
}
