.bankAccount {
  padding: 16px;
  min-height: 100%;
  max-height: fit-content;
  background-color: white;
  &__form {
    padding: 8px 16px;
  }
  &__footer {
    border-top: 1px solid grey;
    width: 100%;
    padding: 16px;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
.addBankAccount {
  height: 64px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  padding: 12px;
  align-items: center;

  &_iconBox {
    padding: 8px 18px;
    gap: 8px;
    width: 59.02px;
    height: 40px;
    border: 1px dashed #2278ff;
    border-radius: 3px;
  }
  &_headText {
    width: 115px;
    height: 21px;
    margin-left: 12px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2278ff;
  }
}
.accounts {
  height: 64px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  padding: 12px;
  align-items: center;
  margin-bottom: 8px;
  &_item {
    display: flex;
    flex-direction: row;
    // background-color: #52B062;
    width: 100%;
  }
  &_iconBox {
    padding: 8px 18px;
    gap: 8px;
    width: 59.02px;
    height: 40px;
    border: 1px solid #d8e0f0;
    border-radius: 3px;
  }
  &_headText {
    width: 115px;
    height: 21px;
    margin-left: 12px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
  }
  &_accountDetails {
    display: flex;
    flex-direction: column;
    margin-left: 12px;

    &_accountNumber {
      display: flex;
      flex-direction: row;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
    }
    &_emailText {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #7d8592;
    }
  }
}
.primary {
  display: flex;
  flex-direction: row;
  // align-items: flex-start;
  padding: 12px;
  gap: 35px;
  height: 64px;
  background: #f0f4f8;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  margin-bottom: 8px;
}
.primaryTag {
  padding: 1px 8px;
  width: 59px;
  height: 20px;
  left: 116px;
  top: 2px;
  margin-left: 8px;
  background: rgba(82, 176, 98, 0.1);
  border-radius: 2px;
  &_text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #52b062;
  }
}
.threeDotsBox {
  display: flex;
  right: 1px;
  // background-color: #52B062;
  justify-content: flex-end;
  align-self: center;
  &_icon {
    width: 24px;
    height: 24px;
  }
}
.optionMenu {
  display: flex;
  flex-direction: column;
  // width: 50px;
  // background-color: brown;
  &_optionItem {
    display: flex;
    flex-direction: row;
    //  padding: 20px;
    cursor: pointer;
    // background-color: #52B062;
    width: 100%;
    &_optionIcon {
      width: 40%;
      height: 35px;
      // background-color: #2278ff;
    }
  }
  &_text {
    width: 80%;
    // background-color: #2278ff;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #7d8592;
    margin-left: 4px;
  }
}
.deleteModal {
  width: 286.29px;
  height: 130px;
  // left: 37px;
  // top: 295px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  // background: red;
  // border-radius: 12px;
  &_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0a1629;
  }
  &_breakLine {
    width: 254.29px;
    height: 1px;
    background: #e4e6e8;
    margin-top: 20px;
  }
}
.deleteButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  // background-color: aqua;
  width: 70%;
  &_cancel {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    // display: flex;
    // align-items: center;
    text-align: center;
    color: #7a8b94;
  }
  &_Delete {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    border: none;
    // display: flex;
    // align-items: center;
    text-align: center;
    color: #eb5757;
  }
}
.termsAndConditions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 0px 16px;
  // background-color: #7D8592;
  height: 540px;
  &_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // background-color: #52B062;
    width: 100%;
  }
  &_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0a1629;
  }
  &_img {
    width: 18px;
    height: 18px;
  }
  &_breakLine {
    width: 100%;
    height: 1px;
    background: #e4e6e8;
    margin-top: 10px;
  }
  &_content {
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    flex-direction: column;

    &_item {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      &_header {
        // font-family: 'Open Sauce One';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #3c4852;
      }
      &_description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #7d8592;
        text-align: justify;
      }
    }
  }
  &_agreeBox {
    display: flex;
    width: 318px;
    height: 60px;
    position: fixed;
    margin-bottom: 20px;
    background: #ffffff;
    // border-radius: 0px 0px 12px 12px;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);

    &_cta {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: center;
      padding: 12px 16px;
      margin-left: 16px;
      gap: 8px;
      width: 288px;
      height: 48px;
      background: #2278ff;
      border-radius: 8px;
    }
    &_img {
      width: 16px;
      height: 11px;
    }
  }
}

@media only screen and (min-width: 600px) {
  .bankAccount {
    &__footer {
      display: none;
    }
  }
  .termsAndConditions {
    &_agreeBox {
      margin-left: 15px;
    }
  }
}
