.CreateMessage {
  padding: 16px 16px 32px 16px;
  min-height: 100%;
  max-height: fit-content;
  background-color: white;

  // position: relative;
  &_Heading {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3c4852;
  }
  &_Label {
    margin: 8px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0a1629;
  }
  &_Subtext {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7d8592;
  }
  &_Card {
    padding: 12px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    margin: 16px 0;
  }

  &_Attachments {
    padding: 6px 0;
    margin: 24px 0;
    border-top: 1px solid #e5e5e5;
  }

  &_AttachmentButton {
    padding: 16px 0;
    margin: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2278ff;

    border: 1px dashed #2278ff;
    border-radius: 8px;

    &_Option {
      display: flex;
      align-items: center;
      padding: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.87);

      border-bottom: 1px solid #e5e5e5;
    }
  }

  &_ButtonContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    z-index: 10;

    &_Button {
      padding: 12px;
      background-color: #2278ff;
      border-radius: 4px;
      color: white;
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;

      text-align: center;
    }
  }

  &_Files {
    margin: 12px 0;

    &_File {
      margin: 12px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_Left {
        display: flex;
        align-items: center;
      }
    }
  }

  &_HiddenInput {
    height: 1px;
    width: 1px;
    visibility: hidden;
  }
}

.disabled {
  background-color: #7aa8f3;
  color: rgb(241, 240, 240);
}
