@import "../../scss/main";

.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @include flex-full(center, center);
  border-radius: 8px;
  @include font-medium(600);
  color: $color-pure-white;
  background-color: $color-app-accent;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 12px;
  cursor: pointer;
  border: none;
  outline: none;
  width: 100%;
  transition: transform 0.2s;

  &--bRadius4 {
    border-radius: 4px;
  }
  &--padding6 {
    padding: 6px;
  }
  &--outline {
    background-color: $color-pure-white;
    color: $color-app-accent;
    border: 1px solid $color-app-accent;
    box-shadow: unset;
    box-shadow: inset 0px 0px 0px 1px $color-app-accent;
  }
  &--small {
    width: unset;
    @include font-xs(500);
    padding: 4px 12px;
    border-radius: 4px;
  }
  &--xsmall {
    width: unset;
    @include font-xxs(700);
    padding: 4px 8px;
  }
  &--disabled {
    opacity: 0.5;
    pointer-events: none;
    background: #2278ff;
    opacity: 0.4;
  }
  &:active {
    transform: translateY(1px);
  }

  &--rippleEffect {
    background-position: center;
    transition: background 0.5s;
    &:hover {
      background: $color-app-accent
        radial-gradient(circle, transparent 1%, $color-app-accent 1%)
        center/15000%;
    }
    &:active {
      background-color: rgba(255, 255, 255, 0.1);
      background-size: 100%;
      transition: background 0s;
    }
  }
  &--rippleEffectOutline {
    background-position: center;
    transition: background 0.5s;
    &:hover {
      background: $color-pure-white
        radial-gradient(circle, transparent 1%, $color-pure-white 1%)
        center/15000%;
    }
    &:active {
      background-color: $color-app-accent;
      background-size: 100%;
      transition: background 0s;
    }
  }
}
