.ChannelBadge {
  margin-right: 10px;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #0a1629;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #61bfe9;
  border-radius: 20px;
}
.WhatsAppBadge {
  margin-right: 10px;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #0a1629;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #25d366;
  border-radius: 20px;
}
