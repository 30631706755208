.conditionalAccess {
  // height: 200px;
  margin-top: 15px;
  // background-color: lawngreen;
  display: flex;
  flex-direction: column;
  &_outerBox {
    display: flex;
    flex-direction: row;
    // background-color: khaki;
    width: 100%;
  }
  &_innerBox {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  &_head {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0a1629;
  }
  &_description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7a8b94;
  }
  &_toggle {
    right: 0;
    position: absolute;
    padding: 2px;
    margin-right: 16px;
  }
  &_LinkBox {
    // margin-top: 15px;
    width: 100%;
    // background-color: lavender;

    &_description {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #7a8b94;
      margin-top: 4px;
    }
    &_text {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #2278ff;
    }
  }
}
.freeGroup1 {
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  height: 50px;
  // background-scolor: red;

  border-radius: 8px;
  &_Input {
    display: flex;
    flex: 1;

    // border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 5px;
    justify-content: space-between;
    // background-color: red;
  }
  &_InputBlock {
    // display: flex;
    width: 90%;
    height: 100%;
    justify-content: center;

    margin-top: -15px;
    // border: none;
  }
  &_inputClass {
    // border: none;
    // background-color: #ffad3b;
    // font-weight: "600";
    // font-size: "14px";
    // line-height: "24px";
    // color: "#2278FF";
    // height:"30px";
    // width:"250px"

    // padding: -10px 0px;
  }
  &_Apply {
    justify-content: center;
    align-self: center;
    font-size: 14px;
    color: #7d8592;
    margin-right: 10px;
  }
  &_selected_Applied {
    justify-content: center;
    align-self: center;
    font-size: 14px;
    margin-right: 10px;
    color: #2278ff;
  }
}
