.readMoreText {
  width: 100%;
  border: none;
  resize: none;
  outline: none;
  overflow: hidden;
  white-space: pre-wrap;

  &_readMoreBTN {
    color: #2278ff;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 14px;
    font-weight: 600;
    margin-top: 0.5rem;
    text-align: left;
    padding-left: 0;
  }
}
