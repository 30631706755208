.TelegramAutomation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &_blocks {
    margin-top: 24px;
    padding: 16px;
    width: 90%;
    height: 131px;
    /* White */
    background: #ffffff;
    /* stroke */
    border: 1px solid #d8e0f0;
    border-radius: 16px;
  }
  &_outerBlock {
    height: 24px;
    //   background-color: green;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &_logoTextBlock {
    display: flex;
    flex-direction: row;
  }
  &_optionLogo {
    display: flex;
    justify-content: flex-end;
  }
  &_headText {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0a1629;
    margin-left: 8px;
  }
  &_description {
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7d8592;
    // background-color: green;
  }
  &_Beta {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    gap: 10px;
    // position: absolute;
    width: 41px;
    height: 26px;
    left: 75px;
    top: 5px;
    background: #7d8592;
    border-radius: 11px;
    color: #ffffff;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-left: 10px;
  }
}
