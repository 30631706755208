.profile {
  background-color: white;
  padding-bottom: 150px;

  &_userContainer {
    background-color: white;
    padding-top: 1px;
  }

  &_userFirstRow {
    flex-direction: row;
    display: flex;
    margin-left: 26px;
    margin-top: 16px;
  }

  &_nameIdContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 18px;
  }

  &_username {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0a1629;
  }

  &_userID {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7a8b94;
  }

  &_userSecondRow {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
    margin-left: 16px;
    margin-top: 16px;
  }

  &_userPhoneText {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #7d8592;
  }

  &_userPhone {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #0a1629;
  }

  &_horizontalLine {
    border-radius: 25px;
    border-top-width: 0.5px;
    border-color: black;
    height: 25px;
  }

  &_moreInfoText {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0a1629;
    margin-left: 16px;
    // margin-top: 16px;
  }

  &_GSTRegisteredTitle {
    flex-direction: row;
    display: flex;
    margin-right: 16px;
    margin-left: 16px;
    margin-bottom: 16px;
  }

  &_GSTRegisteredLeft {
    width: 80%;
  }

  &_GSTRegisteredRight {
    justify-content: center;
    flex: 1;
    display: flex;
    margin-top: 6px;
  }

  &_GSTRegisteredLeftOne {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0a1629;
  }

  &_GSTRegisteredLeftTwo {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #7a8b94;
  }

  &_dottedLine {
    border-top: 0.1px dotted;
    margin-right: 16px;
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &_formContainer {
    margin-left: 16px;
    margin-right: 16px;
  }

  &_footerBTN {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    padding: 0 16px 16px;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.24);
    padding-top: 12px;
  }

  &_uploadContainer {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eaf2ff;
    height: 48px;
    width: 168px;
    border: 1px dashed #2278ff;
    border-radius: 14px;
    margin-top: 12px;
    margin-left: 16px;
    margin-bottom: 16px;
  }

  &_uploadContainerFull {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eaf2ff;
    height: 48px;
    width: auto;
    border: 1px dashed #2278ff;
    border-radius: 14px;
    margin-top: 12px;
    margin-left: 16px;
    margin-right: 16px;
    padding-left: 16px;
    margin-bottom: 16px;
  }

  &_uploadContainerDisabled {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f2f2f2;
    height: 48px;
    width: auto;
    border: 1px;
    border-radius: 14px;
    margin-top: 12px;
    margin-left: 16px;
    margin-right: 16px;
    padding-left: 16px;
    margin-bottom: 16px;
  }

  &_uploadIcon {
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }

  &_uploadLabel {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0a1629;
    margin-left: 16px;
  }

  &_fileName {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #0a1629;
  }

  &_switchOnContainer {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  &_fillDetailsText {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7a8b94;
    margin-left: 7px;
  }

  &_gstErrorText {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ff4058;
    margin-top: -16px;
    margin-bottom: 16px;
  }

  &_userImage {
    width: 56px;
    height: 56px;
  }

  &_curveBorder {
    height: 40px;
    border-style: solid;
    border-width: 1px 0 0 0;
    border-radius: 500px 500px 500px 500px;
    border-top: 0.1px solid #8c8c8c;
    box-shadow: 0px -8px rgba(140, 140, 140, 0.1);
    margin-top: 20px;
  }
}
