@import "../../../scss/main";

.sideBarWrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: $color-overlay-black;
  z-index: 500;
  &__sideBar {
    width: 70%;
    height: 100%;
    background-color: $color-pure-white;
    &__top {
      padding: 40px 16px 12px;
      border-bottom: 1px solid rgba(33, 33, 33, 0.08);
      @include flex-dir-col;
      row-gap: 4px;
      h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
      }
      p {
        @include font-small;
        color: $color-text-grey;
      }
    }
    &__bottom {
      &__item {
        padding: 12px 16px;
        @include flex;
        column-gap: 32px;
        align-items: center;
        p {
          @include font-small(500);
          color: $color-text-grey;
        }
        &--isHighlighted {
          background: linear-gradient(
              0deg,
              rgba(34, 120, 255, 0.05),
              rgba(34, 120, 255, 0.05)
            ),
            #ffffff;
          p {
            color: $color-app-accent;
          }
        }
      }
    }
  }
}
