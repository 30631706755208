.success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  text-align: center;
  padding: 0 24px;

  &_text {
    margin-left: 24px;
    margin-right: 24px;
  }

  &_inviteLinkContainer {
    border-width: 1px;
    border-color: gray;
    border-style: double;
    flex-direction: row;
    display: flex;
    margin-top: 14px;
    margin-left: 24px;
    margin-right: 24px;

    &_link {
      font-weight: 400;
      font-size: 14px;
      color: #7a8b94;
      margin-top: 14px;
      margin-bottom: 14px;
      margin-left: 16px;
    }

    &_copy {
      height: 48px;
      width: 48px;
      margin-right: 0%;
    }
  }

  &_orderid {
    color: #2278ff;
    font-weight: 400;
    font-size: 14px;
    margin-top: 8px;
  }

  &_CTAContainer {
    background-color: #2278ff;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 8px;
    margin-top: 24px;
    margin-right: 24px;
    margin-left: 24px;
  }

  &_CTAIcon {
    height: 16px;
    width: 18px;
    margin-right: 10px;
  }

  &_joinText {
    font-size: 16px;
    font-weight: 600;
    color: white;
  }

  &_footNote {
    margin-top: 48px;

    &_title {
      font-weight: 700;
      font-size: 14px;
      color: #7a8b94;
    }

    &_content {
      margin-top: 8px;
      font-weight: 400;
      font-size: 14px;
      color: #7a8b94;
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  &_note {
    &_title {
      width: 100%;
      align-items: center;
      display: flex;
      column-gap: 8px;
      padding: 16px 0 8px 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #3c4852;

      &__hr {
        width: 100%;
        height: 1px;
        background-color: #e5e5e5;
        flex-grow: 1;
      }
    }
  }

  &_text {
    // padding: 0 0 32px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7a8b94;
    margin-top: 8px;
  }

  &_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #3c4852;
      padding: 0 16px;
    }

    &-line {
      border: 1px solid #e5e5e5;
      height: 1px;
      width: 25%;
      margin: 0 8px;
    }
  }

  &_button {
    padding: 20px 0;
    width: 100%;
  }
}
