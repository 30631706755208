.accordian {
  display: flex;

  &_item {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    background-color: #fafafa;
    width: 100%;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    border: 1px solid #f0efef;
  }

  &_title {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #fafafa;
    font-weight: 500;
    font-size: 14px;
    padding: 10px;

    &_top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &_content {
    margin: 10px 0;
    color: #7d8592;
    font-size: 14px;
    font-weight: 400;
    max-height: 200px;
    overflow: hidden;
    animation: groww 500ms linear;
  }
}

@keyframes groww {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 200px;
  }
}
