.inprogress {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 150px;
  text-align: center;
  padding: 0 24px;

  &_orderid {
    color: #2278ff;
    font-weight: 400;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &_img {
    height: 100px;
    width: 100px;
  }

  &_title {
    font-weight: 700;
    font-size: 16px;
    color: #3c4852;
    margin-top: 30px;
    margin-left: 47px;
    margin-right: 47px;
    margin-bottom: 10px;
  }

  &_point {
    font-weight: 400;
    font-size: 14px;
    color: #7a8b94;
    text-align: center;
    margin-top: 10px;
    margin-right: 24px;
    margin-left: 24px;
  }
}
