.LinkExisting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &_block {
    padding: 16px;
    margin-top: 16px;
    width: 90%;
    // height: 390.2px;
    background: #ffffff;
    border: 1px solid #d8e0f0;
    border-radius: 16px;
  }
  &_linkLogo {
    display: flex;
    justify-content: space-around;
    //   background-color: green;
    &_img {
      align-self: center;
      width: 152px;
      height: 24px;
    }
  }
  &_content {
    margin-top: 24px;

    &_steps {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #0a1629;
    }
    &_points {
      margin-top: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #7d8592;
    }
    &_bold {
      color: #0a1629;
      font-weight: 500;
    }
  }
  &_CTA {
    margin-top: 24px;
    height: 56px;
    width: 100%;
    background: #2278ff;
    border-radius: 14px;
    &_logoText {
      display: flex;
      flex-direction: row;
      justify-content: center;
      // background-color: green;
      // margin-top: 90px;
    }
    &_text {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      margin-right: 9px;
      margin-top: 15px;
    }
    &_img {
      width: 24px;
      height: 24px;
      margin-top: 15px;
    }
    &_disabled {
      margin-top: 24px;
      height: 56px;
      width: 100%;
      background: #2278ff;
      border-radius: 14px;
      opacity: 0.5;
      pointer-events: none;
      background: #2278ff;
      opacity: 0.4;
    }
  }
  &_videoBlock {
    margin-top: 24px;
    height: 52px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &_columnBlock {
      display: flex;
      flex-direction: column;
    }
    &_text {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #7d8592;
    }
    &_videoLogo {
      width: 64px;
      height: 52px;
    }
  }
}
