.register {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 109px;
  height: 100vh;
  &_heading {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &-image {
      margin: 16px 16px 16px 0;
    }
    &-text {
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      color: #333333;
    }
  }
  &_footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    padding: 0 16px 16px;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.24);

    color: rgba(0, 0, 0, 0.6);
    &-container {
      font-style: normal;
      font-weight: 450;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 0 16px 0;
    }
    &-img {
      padding-right: 8px;
    }
    &-button {
      margin-top: 5px;
    }
  }
  &_note {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7d8592;
  }

  &__searchcountrybox {
    width: 100%;
    margin-top: 20px;

    &__input {
      width: 100%;
      height: 46px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
    }
  }

  &__selectcountrybox {
    background-color: white;
    width: 100%;
    height: 300px;
    overflow: "scroll";

    &::-webkit-scrollbar {
      display: none;
    }

    ul {
      list-style: none;
    }

    &__listitem {
      &:first-child {
        margin-top: 24px;
      }
      display: flex;
      justify-content: space-between;
      margin-bottom: 28px;
      width: 100%;
      border-radius: 1px;
      padding: 12px 10px;
      cursor: pointer;

      &__active {
        background: #f7f7f7;
      }

      &__text {
        display: flex;

        &__flag {
          margin-right: 6px;
          display: flex;
          align-self: center;
        }

        &__country {
          margin-right: 3px;
          font-weight: 400;
          font-size: 14px;
          color: #3c4852;
          display: flex;
          align-self: center;
        }

        &__code {
          font-weight: 400;
          font-size: 14px;
          color: #3c4852;
          display: flex;
          align-self: center;
        }
      }
    }
  }
}
