@import "../../scss/main";

.searchScreenContainer {
  background-color: white;
  height: 100%;

  &_coupon {
    font-weight: 500;
    font-size: 12px;
    color: #2278ff;
    background-color: #2278ff1a;
    padding: 5px;
    display: inline;
  }

  &_searchBoxContainer {
    display: flex;
    flex-direction: row;
    height: 56px;
    flex: 1;
    align-items: center;
    padding-right: 16px;
    padding-left: 16px;
    border-bottom: 1px solid #e5e5e5;
  }

  &_searchBox {
    background-color: #f8f8f8;
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-left: 20px;
    height: 34px;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;

    input {
      border: none;
      @include input-noBorder;
    }
  }

  &_search {
    border: none;
    background-color: #f8f8f8;
    color: black;
    padding: 5px;
    display: flex;
    flex: 1;
  }

  &_iconContainer {
    display: flex;
    flex: 0.1;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-right: 3px;
    margin-left: 3px;
  }

  &_itemContainer {
    background-color: white;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 4px;

    &_left {
    }

    &_right {
    }
  }

  &_name {
    font-weight: 600;
    font-size: 14px;
    margin-top: 16px;
    margin-bottom: 4px;
    color: #3c4852;
  }

  &_date {
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &_dateText {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-top: 19px;
    color: #7d8592;
  }

  &_mobile {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.6);
  }

  &_amount {
    font-size: 16px;
    margin-bottom: 4px;
    text-align: right;
    font-weight: 500;
    color: #3c4852;
    margin-top: 18px;
  }

  &_details {
    font-weight: 700;
    font-size: 10px;
    color: #2278ff;
    margin-top: 10px;
    margin-top: 2px;
    text-align: right;
  }

  &_detailsContainer {
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    margin-top: 13px;
    justify-content: space-between;
    margin-right: 16px;
  }

  &_detailsText {
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 4px;
  }

  &_detailsAmount {
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 4px;
  }

  &_lifetime {
    font-weight: 500;
    font-size: 12px;
    color: #52b062;
    background-color: rgba(82, 176, 98, 0.1);
    padding: 5px;
    display: inline;
  }

  &_subscription {
    font-weight: 500;
    font-size: 12px;
    padding: 5px;
    color: #ffad3b;
    background-color: rgba(255, 173, 59, 0.1);
    display: inline;
    margin-right: 8px;
  }

  &_notJoined {
    font-weight: 500;
    font-size: 12px;
    color: #f65160;
    background-color: rgba(246, 81, 96, 0.1);
    padding: 5px;
    display: inline;
    margin-right: 8px;
  }

  &_joined {
    font-weight: 500;
    font-size: 12px;
    color: #52b062;
    background-color: rgba(82, 176, 98, 0.1);
    padding: 5px;
    display: inline;
    margin-right: 8px;
  }
}
