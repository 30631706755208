@import "../../scss/main";

.container {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 32px;

  &_left {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    padding-bottom: 20px;

    &_text {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      margin-left: 16px;
      margin-top: 6px;

      &_top {
        margin-bottom: 2px;
        font-weight: 500;
        font-size: 16px;
        color: #000000de;
      }

      &_bottom {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  &_right {
    display: flex;
    align-items: center;

    &_arrow {
      height: 13px;
      width: 10px;
    }
  }
}
