.CouponCard {
  background: #eaf2ff;
  border-radius: 4px;
  margin-top: 8px;
  // padding:13px;

  &_Container {
    display: flex;
    flex-direction: row;
    &_CouponStripe {
      width: 32px;
      height: 114px;
      background: #2278ff;
      border-right: 1px dashed #ffffff;
      border-radius: 4px 0px 0px 4px;
      display: flex;
      flex-direction: row;
    }
  }
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 16px;
  height: 16px;
}
.rightCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-right: -4px;
  width: 16px;
  height: 16px;
}
.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.25;
  transform: rotate(-90deg);
  width: 100%;
  margin-top: 25px;
  // margin-right: 20px;
}
.mainContent {
  width: 100%;
  //   background-color: aqua;
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  &_data {
    width: 70%;
    height: 100%;
    // background-color:blueviolet;
    display: flex;
    flex-direction: column;
    padding: 12px;
    &_text {
      color: #0a1629;
      font-weight: 600;
      font-size: 16px;
      line-height: 14px;
    }
  }
  &_head {
    display: flex;
    flex-direction: row;
    // background-color: aqua;
    &_img {
      width: 18px;
      height: 18px;
    }
    &_text {
      font-weight: 600;
      font-size: 16px;
      line-height: 14px;
      color: #0a1629;
      margin-left: 4px;
      margin-top: 2px;
      width: 150px;
      word-wrap: break-word;
      // background-color: bisque;
    }
  }
  &_discountAmount {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #1ec86c;
    margin-top: 7px;
  }
  &_timeAndEarning {
    display: flex;
    flex-direction: row;
    margin-top: 23px;
    &_item {
      display: flex;
      flex-direction: column;
      &_text {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #0a1629;
      }
      &_tag {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #7d8592;
      }
    }
    &_itemEarning {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }
  }
  &_Options {
    display: flex;
    flex-direction: column;
    width: 30%;
    // position: relative;
    &_toggle {
      padding-top: 20px;
      display: flex;
      // align-content: center;
      justify-content: right;
      margin-right: 12px;
    }
    &_shareAndThreeDots {
      display: flex;
      flex-direction: row;
      margin-top: 48px;
      // align-content: center;
      justify-content: right;
      // position: absolute;
      bottom: 1;
      &_shareBtn {
        width: 18px;
        height: 18px;
      }
      &_threeDots {
        width: 20px;
        height: 20px;
        margin-left: 25px;
      }
    }
  }
}
.optionMenu {
  display: flex;
  flex-direction: column;
  //   width: 50px;
  //   background-color: brown;
  &_optionItem {
    display: flex;
    flex-direction: row;
    padding: 5px;
    cursor: pointer;
    &_optionIcon {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
  &_text {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #7d8592;
  }
}
.deleteModal {
  width: 286.29px;
  height: 130px;
  // left: 37px;
  // top: 295px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  // background: red;
  // border-radius: 12px;
  &_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0a1629;
  }
  &_breakLine {
    width: 254.29px;
    height: 1px;
    background: #e4e6e8;
    margin-top: 20px;
  }
}
.deleteButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  // background-color: aqua;
  width: 70%;
  &_cancel {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    // display: flex;
    // align-items: center;
    text-align: center;
    color: #7a8b94;
  }
  &_Delete {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    border: none;
    // display: flex;
    // align-items: center;
    text-align: center;
    color: #eb5757;
  }
}
