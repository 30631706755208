.preAuth {
  display: flex;
  flex-direction: column;
  padding-top: 60px;

  &_head {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    flex-direction: row;
    margin-top: 22px;
    margin-right: 10px;
    border-bottom: 1px dashed #e5e5e5;
    // background-color: aqua;

    &-img {
      margin: 22px 0 20px 0;
    }

    &-text {
      margin: 0px 0px 0px 20px;
      align-self: center;
      font-size: 20px;
      font-weight: bold;
    }
  }

  &_Badges {
    align-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    padding-top: 20px;
  }

  &_description {
    border-top: 1px dashed #e5e5e5;
    border-bottom: 1px dashed #e5e5e5;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    margin: 0 16px;
    width: calc(100% - 32px);

    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;

      color: #3c4852;
    }

    &-content {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #1a181e;
      padding-top: 16px;
    }
  }

  &_pricing {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;

    width: 100%;

    &-payDiv {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
      padding: 14px 16px;
    }

    &-payDivText {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #3c4852;
    }

    &-payDivPrice {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #3c4852;
    }

    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #3c4852;
    }

    &-content {
      margin: 0px 0 0 4px;
    }

    &_list {
      align-items: center;
      justify-content: space-between;
      display: flex;
      margin-bottom: 22px;

      &-label {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-labelDiv {
        display: flex;
        align-items: center;
      }

      &-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #7a8b94;
        margin-left: 12px;
      }

      &-price {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #3c4852;
      }
      &_extraoff {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #1ec86c;
        margin-top: 4px;
      }
    }
    &_Column {
      display: flex;
      flex-direction: column;
    }
  }
  &_expiryBlock {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 97%;
    margin-top: 20px;
    background: rgba(255, 173, 59, 0.1);
    border: 1px solid #ffad3b;
    border-radius: 8px;
    &_calendarText {
      display: flex;
      flex-direction: row;
      align-self: flex-start;
      padding-left: 10px;
      padding-top: 10px;
    }
    &_dateExpiry {
      display: flex;
      justify-content: space-between;
      padding-top: 5px;
      padding-left: 10px;
      padding-right: 10px;
      //  background-color: aqua;
    }
  }

  // &_SinglePlan {
  //   width: 100%;
  //   margin: 10px 0 0 0;
  //   padding: 10px;
  //   border: 2px solid rgb(144, 144, 144);
  //   border-radius: 0px 8px 8px 8px;

  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   position: relative;
  //   background-color: white;
  //   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  //   &_Right {
  //     display: flex;
  //     align-items: center;
  //     // justify-content: ;
  //   }

  //   &_Offer {
  //     background: linear-gradient(147.14deg, #00cfde 6.95%, #05a660 93.05%);
  //     border-radius: 10px 10px 0px 0px;
  //     color: white;
  //     font-style: normal;
  //     font-weight: 600;
  //     font-size: 12px;
  //     line-height: 18px;
  //     padding: 4px 23px;
  //     height: 24px;

  //     // z-index: 1;
  //     // position: absolute;
  //     // left: -2px;
  //     // top: -26px;
  //   }
  // }
  &_footer {
    background: #ffffff;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.24);
    width: 100%;
    position: fixed;
    bottom: 0px;

    &-text {
      font-size: 20px;
      font-weight: bold;
    }
    &_free {
      display: flex;
      flex-direction: column;
    }

    &-button {
      width: 50%;
    }
  }

  &_FAQs {
    display: flex;
    padding: 20px 16px;
    align-self: flex-start;
    flex-direction: column;
    width: 100%;

    &_title {
      font-size: 28px;
      font-weight: 900;
      color: #0a1629;
    }
  }

  &_SinglePlan {
    width: 100%;
    margin: 0px 0 0 0;
    padding: 20px;
    border: 2px solid rgb(144, 144, 144);
    border-radius: 0px 8px 8px 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    // position: relative;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 100ms;

    &_Left {
      display: flex;
      align-items: center;

      & > input {
        margin-right: 10px;
      }
    }

    &_Right {
      display: flex;
      align-items: center;
      // justify-content: ;
    }

    &_Offer {
      background: linear-gradient(147.14deg, #00cfde 6.95%, #05a660 93.05%);
      border-radius: 10px 10px 0px 0px;
      color: white;
      font-style: normal;
      width: 25%;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      padding: 4px 23px;
      height: 24px;
      margin-top: 20px;
      // z-index: 1;
      // position: absolute;
      // left: -2px;
      // top: -26px;
    }

    &_Period {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #7a8b94;
    }

    &_Price {
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      text-decoration-line: line-through;
      color: #7a8b94;
      margin-right: 10px;
    }

    &_FinalPrice {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #2278ff;
    }
  }

  &_support {
    width: 100%;
    padding: 20px 0;
    text-align: center;
    border-top: 1px dashed #e5e5e5;
    border-bottom: 1px dashed #e5e5e5;

    &_text {
      color: rgb(111, 111, 111);
    }
    &_mail {
      margin: 5px 0;
      text-decoration: underline;
      color: black;
    }
  }

  &_bottom {
    padding: 20px;
    width: 100%;

    &_top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      font-size: 12px;
      line-height: 16px;
      color: #7d8592;

      &_right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        & > div {
          margin: 5px 0 0 0;
          width: 60%;
          text-align: right;
        }
      }
    }

    &_foot {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #7d8592;
      text-align: center;
      text-decoration: underline;

      & > div {
        margin: 5px 0;
      }
    }
  }
  .selectedPlan {
    border: 2px solid #2278ff;
    background-color: #e3efff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
}
.renewHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 148px;
  position: fixed;
  width: 100%;
  height: 60px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.1);
}
.renewHeaderDownload {
  margin-top: 10px;
  padding: 10px 40px;
  display: flex;
  flex-direction: row;
}
.safeAndSecure {
  color: #1ec86c;
  font-weight: bold;
  margin-left: 2px;
  font-size: 16px;
}
.shieldSize {
  width: 24px;
  height: 22px;
}
.freeGroup {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  &_Input {
    display: flex;
    flex: 1;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 5px;
    justify-content: space-between;
    // background-color: red;
  }
  &_InputBlock {
    //  background-color: #cb0606;
    width: 70%;
    margin-top: -20px;
    border: none;
  }
  &_inputClass {
    border: none;
    // background-color: #ffad3b;
    padding: -10px 0px;
  }
  &_Apply {
    justify-content: center;
    align-self: center;
    font-size: 14px;
    color: #7d8592;
    margin-right: 10px;
  }
  &_selected_Applied {
    justify-content: center;
    align-self: center;
    font-size: 14px;
    margin-right: 10px;
    color: #2278ff;
  }
}
.errorContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: 60px;
  left: 0px;
  top: -60px;
  background: #e3efff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  padding: 9px 16px;

  &_redContainer {
    padding: 4px 81px;
    gap: 4px;
    position: absolute;
    width: 100%;
    height: 26px;
    left: 0px;
    top: -26px;
    background: #cb0606;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
  }
}
.successCodeBlock {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 18px;
  color: #3c4852;
  width: 300px;
  align-self: center;
  justify-content: center;
  // background-color: #00cfde;
  &_name {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #3c4852;
    // width: 102px;
    max-width: 102px;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 0px;
  }
  // background-color: red;
}
.editBtn {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-self: center;
  margin-left: 10px;
  // background-color: red;
  &_img {
    width: 15px;
    height: 15px;
  }
  &_text {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #2278ff;
    margin-left: 3px;
  }
}
.priceBlock {
  display: none;
  margin-left: 0px;
  &_img {
    width: 21px;
    height: 21px;
  }
}
.priceDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 16px 16px;
  gap: 22px;
  position: absolute;
  width: 100%;
  height: 269px;
  bottom: 0px;
  background: #ffffff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px 12px 0px 0px;

  &_priceDetailItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // background-color: red;
    width: 100%;
    // padding: 14px 14px;
    &_orderSummary {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #3c4852;
      padding: 14px 14px;
    }
    &_bold {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #3c4852;
      // padding: 14px 14px;
    }
    &_text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #7d8592;
    }
    &_links {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #2278ff;
    }
    &_price {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #3c4852;
    }
    &_payable {
      font-weight: 600;
      font-size: 16px;
      line-height: 17px;
      color: #3c4852;
    }
  }
  &_couponNameBox {
    display: flex;
    flex-direction: column;
  }
}
.savedPrice {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #1ec86c;
}
.priceOuterBlock {
  display: flex;
  flex-direction: column;
}
.couponLoader {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CouponCodeBlock {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 88px;
  gap: 10px;
  position: absolute;
  width: 100%;
  height: 26px;
  left: 0px;
  top: -26px;
  background: #e3efff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);

  &_haveACouponCode {
    display: flex;
    flex-direction: row;
    align-content: center;
  }
  &_img {
    width: 16px;
    height: 16px;
  }
  &_text {
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #2278ff;
    margin-left: 3px;
  }
}
.CouponCodeOpen {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: 58px;
  left: 0px;
  top: -58px;
  background: #e3efff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  padding: 9px 16px;

  &_head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &_openText {
    // font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #0a1629;
    margin-left: 3px;
  }
  &_close {
    width: 16px;
    height: 16px;
  }
  &_row {
    display: flex;
    flex-direction: row;
  }
}
.usageLimit_preAuth {
  &_title {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #3c4852;
  }
  &_title_preAuth {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #3c4852;
  }
  &_description {
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #7a8b94;
  }
  &_description_preAuth {
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    font-feature-settings: "pnum" on, "lnum" on;
    color: #7a8b94;
  }
}

@media only screen and (min-width: 768px) {
  .preAuth {
    width: 60%;
    display: flex;
    margin: auto;
    // height: 100vh;
    justify-content: center;
    &_footer {
      box-shadow: none;
      position: initial;
    }
  }
}

@media only screen and (max-width: 768px) {
  .priceBlock {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (max-width: 600px) {
  .preAuth {
    padding-bottom: 100px;
    &_SinglePlan {
      &_Offer {
        width: 50%;
      }
    }
    &_expiryBlock {
      width: 92%;
    }
  }
  .renewHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 67px;
    padding: 8px 18px;
    box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.1);
  }
  .renewHeaderDownload {
    padding: 10px 10px;
  }
  .safeAndSecure {
    color: #1ec86c;
    font-weight: bold;
    margin-left: 2px;
    font-size: 12px;
  }
  .shieldSize {
    width: 16px;
    height: 16px;
  }
}
