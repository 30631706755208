@import "../../scss/main";

.myEarningsContainer {
  &__header {
    padding: 28px;
    @include flex-full(center, center, column);
    text-align: center;
    row-gap: 8px;
    border-bottom: 2px solid $color-hr-grey;

    p {
      @include font-small;
      color: $color-text-grey;
    }

    h4 {
      @include font-large(700);
      color: $color-text-black;
    }
  }

  &_temp {
    border-width: 0;
    width: 100px;
  }

  &_earningsContainer {
    background-color: white;
    margin-top: 8px;
    padding-top: 1px;
  }

  &_earningTypeParent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 16px;
  }

  &_earningType {
    background-color: #e4e6e8;
    display: inline-block;
    margin-top: 16px;
    border-radius: 28px;
    justify-content: space-between;
    padding-right: 6px;
    padding-left: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 16px;
  }

  &_typeName {
    display: inline-block;
    margin-right: 2px;
    margin-left: 2px;
    padding: 10px;
    border-radius: 28px;
    font-weight: 500;
    font-size: 12px;
  }

  &_selectedType {
    display: inline-block;
    margin-right: 2px;
    margin-left: 2px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 28px;
    background-color: #2278ff;
    color: white;
    font-weight: 500;
    font-size: 12px;
  }

  &_totalEarningsContainer {
    display: flex;
    flex-direction: row;
    margin-left: 17px;
    margin-right: 20px;
    margin-top: 16px;
    justify-content: space-between;
  }

  &_showTotalEarningsContainer {
    display: flex;
    flex-direction: row;
    // margin-left: 17px;
    // margin-right: 20px;
    margin-top: 16px;
    justify-content: space-around;
  }

  &_showColumn {
    justify-content: center;
    align-items: center;
  }

  &_showColumnRowOne {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
  }

  &_showColumnRowTwo {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #7d8592;
  }

  &_showColumnRowTwoSettlements {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #7d8592;
    justify-content: center;
  }

  &_btSheetListItem {
    flex-direction: row;
    display: flex;
    margin-top: 22px;
  }

  &_gotItBTNContainer {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &_btSheetStepper {
    width: 20px;
    height: 20px;
  }

  &_stepperText {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #7d8592;
    margin-left: 8px;
  }

  &_totalEarningsTitle {
    color: #7d8592;
    font-size: 14px;
    font-weight: 500;
    margin-top: 4px;
  }

  &_totalEarningsValue {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
  }

  &_channelWiseBreakDown {
    background-color: #f2f3f7;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    padding-left: 16px;
    margin-top: 19px;
    margin-bottom: 11px;
    padding-top: 16px;
    padding-bottom: 14px;
  }

  &_DateContainer {
    width: 150px;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #d8e0f0;
    border-radius: 14px;
    font-size: 14px;
    // flex-direction: row;
    display: inline-flex;
    align-items: center;

    &:focus {
      outline: none;
    }
  }

  &__list {
    padding: 0 16px;
    background-color: white;

    &__item {
      @include flex-full(space-between, flex-end);
      padding-top: 16px;
      border-bottom: 1px solid #e4e6e8;
      padding-bottom: 20px;

      &__left {
        @include flex-dir-col;
        row-gap: 8px;

        h2 {
          @include font-small(600);
        }

        &__iconTextPair {
          @include flex-align(center);
          column-gap: 8px;

          h3 {
            @include font-xs(500);
            color: $color-text-grey;
          }

          h4 {
            @include font-xs;
            color: $color-text-grey;
          }

          &__emblem {
            padding: 4px;
            border-radius: 2px;
            @include flex-full(center, center);
            @include font-xs(500);

            &--subscription {
              color: #ffad3b;
              background: rgba(255, 173, 59, 0.1);
            }

            &--lifetime {
              color: #52b062;
              background: rgba(82, 176, 98, 0.1);
            }
          }
        }
      }

      &__right {
        @include font-medium(700);
        color: $color-text-black;
      }
    }
  }
}
