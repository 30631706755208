.AllCoupons {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: #ffffff;
}
.LoaderOverlay {
  position: fixed;
  //    text-align: end;
  width: 100%;
  height: 100%;
  // font-weight: bold;
  align-content: center;
}
.ActiveCoupons {
  &_Container {
    padding: 20px;
    width: 100%;
  }
  &_headText {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #3c4852;
  }
  &_CouponCard {
    margin-top: 8px;
  }
  &_InactiveContainer {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 90px;
  }
}
.ButtonContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 15px;
  border-top: 1px solid #e5e5e5;
  z-index: 10;

  &_Button {
    padding: 12px;
    background-color: #2278ff;
    border-radius: 4px;
    color: white;
    font-size: 1rem;
    line-height: 24px;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }
}
.drop-down {
  list-style: none;
}

.drop-down li {
  padding: 10px 0px;
  border-bottom: 1px solid #cccccc;
  width: 100px;
  text-align: center;
  background: #fbf6f6;
}
.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.Loader {
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NoCoupons {
  display: flex;
  justify-content: center;
  margin-top: 250px;
  color: #7d8592;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
