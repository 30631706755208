.AddNewAdmin {
  background-color: white;
  height: 100%;
  padding: 16px;
  padding-bottom: 100px;

  &_enterAdminHead {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3c4852;
  }

  &_enterAdminSubHead {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7d8592;
    margin-top: 4px;
  }

  &_Form {
    margin-top: 24px;
  }

  &_footerText {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #7d8592;
  }

  &_footerBTNContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    z-index: 10;
  }

  &_OTPSheetHeading {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3c4852;
    margin-top: 16px;
  }

  &_OTPNumber {
    position: relative;
    display: flex;
    border: none;
    background: #f7f7f7;
    border-radius: 4px;
    margin-top: 8px;

    &-code {
      padding: 14px 16px;
      color: #7a8b94;
      border-right: 1px solid #c4cdd5;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    &-input {
      padding: 14px 16px;
      border: none;
      background: #f7f7f7;
      outline: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #7a8b94;
    }

    &-button {
      padding: 14px 13px 14px 16px;
      border: none;
      position: absolute;
      right: 0px;
      top: 0px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #2278ff;
      background: none;
    }
  }

  &_inputStyle {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    outline: none;
    text-align: center;
  }

  &_content {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #3c4852;
    padding: 16px 0px 8px 0;
  }

  &_field {
    display: flex;
  }

  &_gotItBTNContainer {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &_successPopup {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &_successHeading {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }

  &_successText {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #7d8592;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  &_successBTNContainer {
    margin-right: 84px;
    margin-left: 84px;
  }
}
