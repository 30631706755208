.CouponListing {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  &_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    &_text {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #3c4852;
    }
    &_btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #2278ff;
      display: flex;
      flex-direction: row;
    }
  }
  &_coupons {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    &_item {
      width: 144px;
      height: 52px;
      left: 0px;
      top: 0px;
      margin-right: 10px;
      background: #eaf2ff;
      border: 1px solid #2278ff;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      padding: 10px;
    }
    &_img {
      width: 24px;
      height: 24px;
      // align-content: center;
      // justify-content: center;
      margin-left: 3px;
      margin-top: 2px;
      padding: 0px 0px;
    }
    &_block {
      display: flex;
      flex-direction: column;
      // background-color: crimson;
      // align-content: center;
      // margin-left: 15px;
      // margin-right: 20px;
    }
    &_name {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #3c4852;
      width: 102px;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0px 10px;
    }
    &_discount {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #7d8592;
      padding: 0px 10px;
    }
  }
}
div.scrollmenu {
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollmenu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollmenu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.createMore {
  &_img {
    width: 16px;
    height: 16px;
    margin-right: 3px;
  }
}
.emptyCoupon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 3px;
  gap: 4px;
  width: 100%;
  height: 80px;
  background: #eaf2ff;
  border-radius: 4px;
  margin-top: 20px;

  &_header {
    display: flex;
    flex-direction: column;
    width: 60%;
    // background-color: crimson;
    &_text {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #3c4852;
    }
    &_description {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7a8b94;
      margin-top: 4px;
    }
  }
  &_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    margin-left: 10px;
    gap: 4px;
    width: 91px;
    height: 29px;
    background: linear-gradient(0deg, #2278ff, #2278ff), #fc7c49;
    border-radius: 4px;
    &_img {
      width: 16px;
      height: 16px;
    }
    &_text {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }
  }
}
.viewAll {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  width: 154px;
  height: 52px;
  left: 0px;
  top: 0px;
  border-radius: 4px;
  // background-color: aqua;

  &_text {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #2278ff;
    // background-color: burlywood;
  }
  &_img {
    width: 7px;
    height: 7px;
    display: flex;
    align-content: center;
    justify-content: center;
    margin-left: 5px;
    // background-color: brown;
  }
}
.leftCircle {
  display: flex;
  justify-content: flex-start;
  align-self: center;
  margin-left: -14px;
  width: 12px;
  height: 13px;
  // background-color: burlywood;
}
.rightCircle {
  display: flex;
  justify-content: flex-start;
  align-self: center;
  margin-right: -14px;
  width: 12px;
  height: 13px;
  // background-color: burlywood;
}
