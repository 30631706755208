.CreateGroup {
  padding: 16px 16px 32px 16px;
  min-height: 100%;
  max-height: fit-content;
  background-color: white;
  padding-bottom: 100px;

  &_DPContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &_UploadChannelText {
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    color: #2278ff;
    margin-top: 4px;
  }

  &_image {
    border-radius: 50px;
  }

  &_desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7d8592;
  }

  // position: relative;
  &_Heading {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3c4852;
  }

  &_Label {
    margin: 8px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0a1629;
  }

  &_Subtext {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7d8592;
  }

  &_Card {
    padding: 12px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    margin: 16px 0;
  }

  &_ButtonContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    z-index: 10;

    &_Button {
      padding: 12px;
      background-color: #2278ff;
      border-radius: 4px;
      color: white;
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_Addplan {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #2278ff;
    font-weight: bold;
    font-size: 18px;

    & > img {
      margin-right: 8px;
    }
  }

  &_CustomPeriod {
    display: flex;
    flex-direction: row;
    // background-color: grey;
  }

  &_CustomInput {
    width: 50%;
    height: 23px;
  }

  &_CustomPeriod_DropDown {
    //  padding-left: 20px;
    //  background-color: hotpink;
    margin-left: 30px;
    width: 50%;
  }
}

.CollapsedPlan {
  &_Top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #3c4852;

    &_Edit {
      cursor: pointer;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #2278ff;
    }
  }

  &_Mid {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_Period {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #3c4852;
    }

    &_NetPrice {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #08bd80;
    }
  }

  &_Foot {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_Offer {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #f49f29;
    }

    &_Price {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-decoration-line: line-through;
      color: #7a8b94;
    }
  }
}

.disabled {
  background-color: #7aa8f3;
  color: rgb(241, 240, 240);
}

.freeGroup {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  height: 50px;

  &_Input {
    display: flex;
    flex: 1;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 6px;
    justify-content: space-between;
  }

  &_Days {
    justify-content: center;
    align-self: center;
    font-size: 16px;
    color: #7d8592;
  }
  &_Input1 {
    display: flex;
    flex: 1;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 5px;
    justify-content: space-between;
    // background-color: red;
  }

  &_InputBlock1 {
    //  background-color: #cb0606;
    width: 70%;
    margin-top: -20px;
    border: none;
  }

  &_inputClass {
    border: none;
    // background-color: #ffad3b;
    padding: -10px 0px;
  }

  &_Apply {
    justify-content: center;
    align-self: center;
    font-size: 14px;
    color: #7d8592;
    margin-right: 10px;
  }

  &_selected_Applied {
    justify-content: center;
    align-self: center;
    font-size: 14px;
    margin-right: 10px;
    color: #2278ff;
  }
}
