.SingleMessage {
  padding: 16px;
  margin: 4px 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  animation: fadeInMoveTop 500ms;

  &_Left {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;

    &_NameDate {
      margin-left: 16px;
      display: flex;
      // align-items: center;

      &-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }

      &-date {
        margin-top: 8px;
        display: flex;
        align-items: center;

        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  &_Right {
    display: flex;
    align-items: flex-end;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #3c4852;
  }

  &_Status {
    align-self: center;
  }
}
