.AttentionContainer {
  width: 100%;
  height: 96px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  background: #eaf2ff;
  border-radius: 4px;
  &_outerBox {
    display: flex;
    flex-direction: row;
    //   background-color: khaki;
    width: 100%;
    padding: 12px;
  }
  &_innerBox {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-self: center;
  }
  &_head {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3c4852;
    text-align: left;
  }
  &_description {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7a8b94;
    margin-top: 4px;
    text-align: left;
  }
  &_toggle {
    right: 0;
    position: absolute;
    padding: 12px;
    margin-right: 20px;
  }
  &_LinkBox {
    // margin-top: 15px;
    width: 100%;
    // background-color: lavender;

    &_description {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #7a8b94;
      margin-top: 15px;
    }
    &_text {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #2278ff;
    }
  }
  &_CTAContainer {
    background-color: #2278ff;

    flex-direction: row;
    justify-content: center;
    padding: 6px 12px;
    border-radius: 4px;
    height: 33px;
    width: 160px;
    margin-right: 12px;
    align-self: center;
  }

  &_CTAIcon {
    height: 16px;
    width: 18px;
    margin-right: 10px;
  }

  &_joinText {
    font-size: 16px;
    font-weight: 600;
    color: white;
  }
}
.openFormCta {
  width: 101px;
  height: 29px;
  background: linear-gradient(0deg, #2278ff, #2278ff), #fc7c49;
  border-radius: 4px;
  &_text {
    color: white;
  }
}
