.purchase {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .purchase {
    width: 60%;
    margin: auto;
    // height: 100vh;
    display: flex;
    justify-content: center;
  }
}
