@import "../scss/main";

.layout {
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);

  &__header {
    // padding: 16px 20px 16px 16px;
    border-bottom: 1px solid #e5e5e5;
    @include flex-align(center);
    @include font-xm(500);
    justify-content: space-between;
    color: $color-text-black;
    background: $color-pure-white;

    &__left {
      display: flex;
      align-items: center;

      &__back {
        @include flex-align(center);
        padding: 16px;
        padding-right: 18px;
        span {
          min-height: 16px !important;
          min-width: 16px !important;
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;
      padding: 16px;

      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;
    }

    &--accent {
      background: $color-app-accent;
      color: $color-pure-white;
    }

    h1 {
      width: 100%;
      @include font-medium(500);
      margin: 0 18px 0;
      @include text-ellipsis;
    }

    span {
      min-height: 24px;
      min-width: 24px;
    }
  }

  &__content {
    position: relative;
    background: #f2f3f7;
    overflow-y: scroll;
    overscroll-behavior: contain;
    max-width: 100vw;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    @include hide-scrollbar;
    &__addButton {
      width: 48px;
      height: 48px;
      background-color: $color-app-accent;
      border-radius: 50%;
      position: fixed;
      right: 16px;
      bottom: 26px;
      @include flex-full(center, center);
    }
  }

  &__footer {
    @include flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    background: $color-pure-white;
    border-top: 1px solid $color-hr-grey;
    box-shadow: 0px -1px 0px $color-hr-grey;
    padding: 8px 16px;
    &__item {
      p {
        margin: 0;
        @include font-xs;
        letter-spacing: 0.4px;
        color: #aaaaaa;
      }
      &--isActive {
        p {
          color: $color-app-accent;
          font-weight: 600;
        }
      }
    }
  }
}

.placeholder-logo {
  height: 100vh;
  width: 100vw;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__img {
    width: 100%;
    max-width: 410px;
    max-height: 200px;
  }
}
