.Home {
  background-color: #e5e5e5;
  &_empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    display: flex;
    flex-direction: column;

    &-text {
      font-weight: normal;
      font-size: 16px;
      padding-top: 16px;
    }
    &-img {
      height: 120px;
    }
    &-subtext {
      font-weight: 400;
      font-size: 14px;
      padding-top: 8px;
      width: 60%;
      text-align: center;
      line-height: 21px;
      color: #666666;
    }
  }
  &_Tabs {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    &_Tab {
      width: 50%;
      padding: 16px 0;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      border-bottom: 2px solid white;
      transition: all 100ms;
    }

    .selected {
      color: #2278ff;
      border-bottom: 2px solid #2278ff;
    }
  }
}
