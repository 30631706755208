.landingPage {
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  padding-top: 100px;
  padding-left: 100px;
  padding-right: 100px;

  &_head {
    display: flex;
    // align-items: center;
    flex-direction: column;

    width: 100%;
    height: 400px;
    &-img {
      margin: 22px 0 20px 0;
    }

    &-text {
      margin: 16px 0 20px 0;
      font-size: 20px;
      font-weight: bold;
    }
    &-starText {
      margin: 20px 120px 40px 20px;
      font-size: 28px;
      font-weight: bold;
      color: white;
    }
  }

  &_description {
    border-top: 1px dashed #e5e5e5;
    border-bottom: 1px dashed #e5e5e5;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    margin: 0 16px;
    width: calc(100% - 32px);

    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;

      color: #3c4852;
    }

    &-content {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #1a181e;
      padding-top: 16px;
    }
  }

  &_pricing {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;

    width: 100%;

    &-payDiv {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
      padding: 14px 16px;
    }

    &-payDivText {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #3c4852;
    }

    &-payDivPrice {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #3c4852;
    }

    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #3c4852;
    }

    &-content {
      margin: 20px 0 0 4px;
    }

    &_list {
      align-items: center;
      justify-content: space-between;
      display: flex;
      margin-bottom: 22px;

      &-label {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-labelDiv {
        display: flex;
        align-items: center;
      }

      &-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #7a8b94;
        margin-left: 12px;
      }

      &-price {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #3c4852;
      }
    }
  }

  &_footer {
    background: #ffffff;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.24);
    width: 100%;
    position: fixed;
    bottom: 0px;

    &-text {
      font-size: 20px;
      font-weight: bold;
    }

    &-button {
      width: 50%;
    }
  }
  &_starDownload {
    padding: 16px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.24);

    &-text {
      font-size: 20px;
      font-weight: bold;
      color: #0a1629;
    }

    &-button {
      width: 50%;
      background-color: white;
    }
  }
  // &_starBanner{
  // //  display: flex;
  // // padding: 20px 16px 16px 20px;
  // //  align-self: flex-start;
  // //   flex-direction: column;
  // // background-color: grey;
  //   width: 100%;
  //   height: 200px;
  //   background-size: auto;
  // }
  &_FAQs_offering {
    display: flex;
    padding: 20px 16px;
    align-self: flex-start;
    flex-direction: column;
    width: 100%;

    &_title {
      font-size: 28px;
      font-weight: 900;
      color: #0a1629;
    }
  }

  &_FAQs {
    display: flex;
    padding: 20px 16px;
    align-self: flex-start;
    flex-direction: column;
    width: 100%;
    // padding-right: 100px;

    &_title {
      font-size: 28px;
      font-weight: 900;
      color: #0a1629;
    }
  }

  &_starBanner {
    display: flex;
    // align-self: flex-start;

    flex-direction: column;
    width: 100%;
    &_child {
      padding: 20px 16px;
      height: 220px;
      border-radius: 20px;
      background-position: center;
      // background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &_SinglePlan {
    width: 100%;
    margin: 40px 0 0 0;
    padding: 20px;
    border: 2px solid rgb(144, 144, 144);
    border-radius: 0px 8px 8px 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 100ms;

    &_Left {
      display: flex;
      align-items: center;

      & > input {
        margin-right: 10px;
      }
    }

    &_Right {
      display: flex;
      align-items: center;
      // justify-content: ;
    }

    &_Offer {
      background: linear-gradient(147.14deg, #00cfde 6.95%, #05a660 93.05%);
      border-radius: 10px 10px 0px 0px;
      color: white;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      padding: 4px 23px;
      height: 24px;
      z-index: 1;
      position: absolute;
      left: -2px;
      top: -26px;
    }

    &_Period {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #7a8b94;
    }

    &_Price {
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      text-decoration-line: line-through;
      color: #7a8b94;
      margin-right: 10px;
    }

    &_FinalPrice {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #2278ff;
    }
  }

  &_support {
    width: 100%;
    padding: 20px 0;
    text-align: center;
    border-top: 1px dashed #e5e5e5;
    border-bottom: 1px dashed #e5e5e5;

    &_text {
      color: rgb(111, 111, 111);
    }
    &_mail {
      margin: 5px 0;
      text-decoration: underline;
      color: black;
    }
  }
  &_socialIcons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &_child {
      margin-left: 10px;
    }
  }

  &_bottom {
    padding: 20px;
    width: 100%;
    background-color: #fafafa;

    // background-color: indigo;
    &_top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      font-size: 12px;
      line-height: 16px;
      color: #7d8592;
      padding-bottom: 10px;
      // border-bottom: 2px dashed #E5E5E5;
      // background-color: khaki;
      &left {
        display: flex;
      }
      &_right {
        display: flex;
        //   flex-direction: column;
        //   align-items: flex-end;

        & > div {
          margin: 5px 0 0 0;
          width: 60%;
          text-align: right;
        }
      }
    }

    &_foot {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #7d8592;
      text-align: center;
      text-decoration: underline;

      & > div {
        margin: 5px 0;
      }
    }
  }
  .selectedPlan {
    border: 2px solid #2278ff;
    background-color: #e3efff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
}
.starBanner {
  width: 100%;
  height: 200px;
  background-size: auto;
}
.downloadStarButton {
  max-width: 220px;
  margin-left: 20px;
  padding: 10px 40px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.offeringContainer {
  width: 100%;
  // height: 400px;

  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
  // background-color: hotpink;
  // padding-right: 100px;
}
.offeringMiniContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // background-color: indianred;
  margin-top: 25px;
  height: auto;
}
.offeringElement {
  width: 50%;
  display: flex;
  flex-direction: column;
  // padding: 10px;
  // background-color: khaki;

  &_title {
    // font-family: 'Open Sauce One';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-top: 29px;
  }
  &_description {
    // font-family: 'Open Sauce One';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7a8b94;
    margin-top: 9px;
    width: 95%;
  }
}
.offeringImage {
  width: 70px;
  height: 70px;
}
.topHeadContainer {
  display: flex;
  flex-direction: row;
  width: 100%;

  // height: 500px;
  // background-color: lawngreen;
  &_title {
    // font-family: 'Open Sauce One';

    font-style: normal;
    font-weight: 900;
    font-size: 26pt;
    // line-height: 78px;
    display: flex;
    justify-content: center;
    color: #0a1629;
  }
  &_description {
    margin-top: 24px;
    color: #7d8592;
  }
  &_backBanner {
    width: 90%;
    height: 90%;
  }
  &_backBanner1 {
    display: none;
  }
}
.topHeadBanner {
  display: flex;
  // justify-content: flex-end;
  width: 50%;
}
.topHeadTextBtnBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // background-color: lightblue;
  margin-top: 10px;
  padding: 30px;
  width: 50%;
}
.topHeadTextBtnBlock1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // background-color: lightblue;
  margin-top: 10px;
  padding: 30px;
  width: 50%;
}
.topHeadDownload {
  max-width: 220px;
  margin-top: 20px;
  padding: 10px 40px;
  background-color: #2278ff;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 148px;
  gap: 10px;
  position: fixed;
  width: 100vw;
  height: 80px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.1);
}
.headerDownload {
  max-width: 220px;
  margin-top: 10px;
  padding: 10px 40px;
  background-color: #2278ff;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.footerDownload {
  max-width: 220px;
  margin-top: 30px;
  padding: 10px 40px;
  background-color: #2278ff;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
@media only screen and (min-width: 1024px) {
  .landingPage {
    &_footer {
      box-shadow: none;
      position: initial;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .landingPage {
    padding-bottom: 10px;
    padding-top: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .topHeadContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &_title {
      // font-family: 'Open Sauce One';
      margin-top: 50px;
      font-style: normal;
      font-weight: 900;
      font-size: 26pt;
      // line-height: 78px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #0a1629;
    }
    &_description {
      text-align: center;
    }
    &_backBanner {
      display: none;
      // background-color: indianred;
    }
    &_backBanner1 {
      display: flex;
      width: 140%;
      height: 130%;
    }
  }
  .topHeadTextBtnBlock {
    width: 100%;
    // background-color: indigo;
  }
  .topHeadTextBtnBlock1 {
    display: none;
    // background-color: indigo;
  }
  .topHeadDownload {
    align-self: center;
  }
  .landingPage {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 50px;
    &_FAQs {
      &_title {
        align-self: center;
      }
    }
    &_bottom {
      &_top {
        flex-direction: column;
      }
    }
    &_starBanner {
      // display: flex;
      // align-self: flex-start;
      // flex-direction: column;
      // width: 100%;
      // background-color: chocolate;
      &_child {
        // padding: 20px 16px;
        width: 100%;
        background-position: center;
        height: 264px;
      }
    }
    &_head {
      &-starText {
        margin: 20px 10px 20px 20px;
        font-size: 22px;
        line-height: 32px;
        font-weight: 700;
        color: white;
        text-align: center;
      }
    }
  }
  .offeringMiniContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .offeringElement {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-bottom: 20px;
    &_title {
      align-self: center;
    }
    &_description {
      align-self: center;
      text-align: center;
    }
  }
  .offeringImage {
    width: 70px;
    height: 70px;
    align-self: center;
  }
  // .footerDownload{
  //   max-width:220px;
  //   margin-top: 30px;
  //   padding: 10px 40px;
  //   background-color:#2278FF;
  //   border-radius: 20px;
  //   display: flex;
  //   flex-direction: row;
  //  }
  .footerDownload {
    margin-top: 10px;
  }
  .footerMobile {
    margin-top: 25px;
  }
  .headerContainer {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    padding: 8px 18px;

    position: fixed;
    // width: 1440px;
    height: 80px;
    // left: calc(50% - 1440px/2);
    // top: 0px;
    // background: #FFFFFF;
    // background-color: khaki;
    box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.1);
  }
  .downloadStarButton {
    // max-width:220px;
    margin: 0 auto 0 auto;
    align-self: center;
  }
  .headerDownload {
    display: none;
  }
  .offeringContainer {
    padding-right: 0px;
  }
}
