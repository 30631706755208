.AdminList {
  background-color: white;
  height: 100%;
  padding: 16px;
  padding-bottom: 100px;

  &_rowOne {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &_rowOneLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &_rowTwo {
    display: flex;
    flex-direction: row;
    margin-top: 9px;
    align-items: center;
  }

  &_user {
    height: 24px;
    width: 24px;
  }

  &_userName {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3c4852;
    margin-left: 8px;
  }

  &_phone {
    height: 13px;
    width: 13px;
    margin-left: 2px;
  }

  &_phoneText {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 15px;
  }

  &_separator {
    opacity: 0.5;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &_statusTextGreen {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    background-color: rgba(82, 176, 98, 0.1);
    padding: 1px 6px;
    align-self: center;
    color: #52b062;
  }

  &_statusTextRed {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    background-color: rgba(255, 173, 59, 0.1);
    padding: 1px 6px;
    align-self: center;
    color: #ffad3b;
  }

  &_ButtonContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    z-index: 10;

    &_Button {
      padding: 12px;
      background-color: #2278ff;
      border-radius: 4px;
      color: white;
      font-size: 1rem;
      line-height: 24px;
      font-weight: 500;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_img_icon {
      color: #32a22a;
    }
  }
}
