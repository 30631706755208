.CategoryBadge {
  padding: 5px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #0a1629;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #566ce4;
  border-radius: 20px;
}
