@import "../../scss/main";

.ModalWrapper {
  position: fixed;
  left: 0;
  bottom: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  transition: opacity 2s linear, z-index 0.1s linear;

  z-index: 100;
}

.Backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;

  left: 0;
  top: 0;

  transition: background-color 0.1s linear;

  &__Show {
    background-color: rgba($color: #000000, $alpha: 0.6);
  }

  &__Hide {
    background-color: rgba($color: #000000, $alpha: 0);
  }
}

.Modal {
  width: 100vw;
  background-color: #ffffff;

  position: relative;

  // animation: appear 1s linear;
  transition: bottom 500ms linear;

  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  max-height: 90vh;

  &--Close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__Show {
    bottom: 0;
    --finalPosition: 0vh;
    animation: slide-in 200ms linear;
  }

  &__Hide {
    bottom: -100vh;
  }

  &__Head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;

    &--Details {
      display: block;
      width: calc(100% - 24px);

      span {
        margin-right: 8px !important;
      }

      div {
        @include flex-align(center);
      }
    }
  }

  &__Body {
    max-height: 80vh;

    min-height: 26vh; // JISKO SABSE CHOTA MODAL USKI HEIGHT RAKH DENA IDHAR

    overflow-y: auto;
    overscroll-behavior: contain;
    padding: 0px 16px;
  }

  &__Footer {
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px;

    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(242, 242, 242, 0.25);

    &__Fixed {
      position: fixed;
    }
    &__Absolute {
      position: absolute;
    }
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateY(0);
  }
}
