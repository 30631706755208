.Statement {
  height: 100%;
  overflow-y: scroll;
  &_Container {
    margin-bottom: 20px;
    background-color: red;
    height: 100px;
  }
  &_Top {
    padding: 30px 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &_Head {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.6);
    }

    &_Earnings {
      margin-top: 8px;
      font-weight: 700;
      font-size: 24px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
