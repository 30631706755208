@import "../../scss/main";

.dropdownLabel {
  @include font-small;
  color: $color-text-black;
}
.Dropdown {
  margin: 8px 0;

  &__Main {
    padding: 12px 16px;
    border: 1px solid $color-stroke-grey;
    border-radius: 14px;
    position: relative;
    @include flex-align(center);
    justify-content: space-between;

    div {
      border: none;
      padding: 15px 0;
      width: 100%;
    }

    &--Heading {
      font-style: normal;
      @include font-small(600);
      line-height: 24px;
      color: #666666;
    }

    &--Toggle {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      min-width: 24px;
      min-height: 24px;
      max-width: 24px;
      max-height: 24px;

      transition: background-color 0.5s linear;

      border-radius: 50%;

      &--Verticle {
        position: absolute;

        transition: transform 0.3s linear;

        &--Show {
          transform: rotate(180deg);
        }

        &--Hide {
          transform: rotate(0deg);
        }
      }
    }
  }

  &__Extended {
    overflow: auto;
    background: $color-pure-white;
    border: 1px solid #e9eef2;
    box-shadow: 0px 4px 16px rgba(102, 102, 102, 0.1);
    border-radius: 4px;
    padding: 4px;

    &--Hide {
      // max-height: 0;
      display: none;
      transition: padding-top 0.2s cubic-bezier(0, 1, 0, 1),
        max-height 0.3s cubic-bezier(0, 1, 0, 1);
    }

    &--Show {
      // max-height: 150px;
      display: block;
      transition: padding-top 0.3s linear, max-height 0.3s linear;
      padding-top: 8px;
      margin-top: 4px;
    }
    &__item {
      padding: 8px 12px;
    }
    &__showNew {
      position: absolute;
      z-index: 1000;
      width: 92%;
    }
  }
}
