.Earnings{
    padding:16px;
    margin-top: 16px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    text-align: center;

    &_Top{
        
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        border-bottom: 1px solid #e5e5e5;
       
        padding-bottom: 12px;

        &_Text{
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
           color: #7A8B94;
        }

        &_Subtext{
            margin-top: 4px;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
           color: #3C4852;
        }
    }

    &_Footer{
        margin-top: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #2278FF;
    }
}
