.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(45, 47, 67, 0.8);
  z-index: 18;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tcBox {
  height: 80%;
  padding: 24px 0px;
  padding-bottom: 0px;
  position: fixed;
  width: 85%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);

  &__heading {
    padding: 0px 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #333333;
    margin-bottom: 24px;
  }

  &__text {
    padding: 0px 16px;
    margin-bottom: 16px;
    height: 75%;
    overflow-y: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7d8592;
    text-align: justify;
  }

  &__footer {
    flex: 1;
    background: #ffffff;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
    padding: 16px;
    border-radius: 0px 0px 8px 8px;
  }

  &__checkbox {
    display: flex;
    align-items: center;
    margin-left: -10px;

    &__text {
      align-self: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #1a181e;
    }

    &__linkText {
      &:link,
      &:visited {
        color: #1a181e;
      }
      &:hover {
        color: #1a181e;
      }
    }
  }

  &__button {
    width: 100%;
    text-align: center;
    padding: 12px 10px;
    background: #009ae0;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fafafa;
    outline: none;
    border: none;
    cursor: pointer;

    &:disabled {
      background: #e0e0e0;
      cursor: default;
    }
  }
}
