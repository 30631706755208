@import "../../scss/main";

.inputLabel1 {
  @include font-small;
  color: $color-text-black;
}
.inputContainer1 {
  margin: 20px 0 16px;
  @include flex-align(center);
  border: 1px solid $color-stroke-grey;
  padding: 5px 10px;
  border-radius: 0px;

  &__icon1 {
    @include flex-align(center);
    margin-right: 16px;
  }

  input,
  textarea {
    width: 100%;
    @include font-small;
    color: $color-text-black;
    border: none;
    outline: none;
    resize: none;
    font-family: Roboto;
    @include input-noBorder;
    &::placeholder {
      color: $color-text-grey;
      font-family: Roboto;
    }
    user-select: text !important;
  }
}
