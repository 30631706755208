@import "../../../../scss/main";

.TabBar {
  background-color: #ffffff;
  border-bottom: 1px solid #e5e5e5;

  position: sticky;
  top: -0.5px;

  z-index: 3;

  &__List {
    display: grid;
    grid-template-columns: repeat(1, minmax(0px, 1fr));
    // grid-template-columns: 50% 50%;

    &--Item {
      // @include font-xs(500);
      font-size: 16px;
      color: $color-text-black;
      white-space: nowrap;
      padding: 16px 0 10px;
      color: $color-text-black;
      border-bottom: none;
      @include flex-justify(center);

      &--active {
        color: $color-app-accent;
        border-bottom: 2px solid $color-app-accent;
      }
    }
  }

  &__ListReport {
    display: grid;
    // grid-template-columns: repeat(1, minmax(0px, 1fr));
    grid-template-columns: 50% 50%;

    &--Item {
      // @include font-xs(500);
      font-size: 16px;
      color: $color-text-black;
      white-space: nowrap;
      padding: 16px 0 10px;
      color: $color-text-black;
      border-bottom: none;
      @include flex-justify(center);

      &--active {
        color: $color-app-accent;
        border-bottom: 2px solid $color-app-accent;
      }
    }
  }
}
