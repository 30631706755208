.WhatsAppBlock_container {
  display: flex;
  flex-direction: column;
  width: 328px;
  background: #f1f7ff;
  border-radius: 16px;
  margin-top: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  padding: 16px;
  // justify-content: center;
  &_head {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #0a1629;
    align-self: flex-start;
    padding: 16px;
  }
  &_apiFail1 {
    display: flex;
    flex-direction: column;
    margin-top: -30px;
    padding: 16px;
    &_points1 {
      align-self: flex-start;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #7d8592;
      text-align: justify;
      margin-top: 10px;
    }
  }
}
.whatsappctaBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 24px;
  padding-left: 20px;
  padding-right: 20px;
  // background-color: aqua;
  &_cancelCTA {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 6px;
    width: 154px;
    height: 56px;
    // background: #2278FF;
    border: 1px solid #2278ff;
    border-radius: 16px;
    color: #2278ff;
    font-weight: bold;
  }
  &_confirmCTA {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 6px;
    width: 100%;
    height: 56px;
    background: #2278ff;
    border-radius: 16px;
    color: #ffffff;
    font-weight: bold;
  }
}
