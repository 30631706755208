@import "../../scss/main";

.inputLabel {
  @include font-small;
  color: $color-text-black;
}
.inputContainer {
  margin: 8px 0 16px;
  @include flex-align(center);
  border: 1px solid $color-stroke-grey;
  border-radius: 8px;
  padding: 15px 19px;
  background-color: $color-pure-white;
  border-radius: 14px;

  &__icon {
    @include flex-align(center);
    margin-right: 16px;
  }

  &__countryicon {
    @include flex-align(center);

    cursor: pointer;

    span {
      display: inline-block;
      margin-left: 8px;
      margin-right: 0px;
      @include font-small;
    }
  }

  input,
  textarea {
    width: 100%;
    @include font-small;
    color: $color-text-black;
    border: none;
    outline: none;
    resize: none;
    font-family: Roboto;
    @include input-noBorder;
    &::placeholder {
      color: $color-text-grey;
      font-family: Roboto;
    }
    user-select: text !important;
  }
}
.accountInputContainer {
  margin: 8px 0 16px;
  @include flex-align(center);
  border: 1px solid #cb0606;
  border-radius: 8px;
  padding: 15px 19px;
  background-color: $color-pure-white;
  border-radius: 14px;

  &__icon {
    @include flex-align(center);
    margin-right: 16px;
  }

  input,
  textarea {
    width: 100%;
    @include font-small;
    color: $color-text-black;
    border: none;
    outline: none;
    resize: none;
    font-family: Roboto;
    @include input-noBorder;
    &::placeholder {
      color: $color-text-grey;
      font-family: Roboto;
    }
    user-select: text !important;
  }
}
