.success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 122px;
  text-align: center;
  padding: 0 24px;
  &_note {
    &_title {
      width: 100%;
      align-items: center;
      display: flex;
      column-gap: 8px;
      padding: 16px 0 8px 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #3c4852;
      &__hr {
        width: 100%;
        height: 1px;
        background-color: #e5e5e5;
        flex-grow: 1;
      }
    }
  }
  &_CTAContainer {
    background-color: #2278ff;
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 8px;
    margin-top: 24px;
    margin-right: 24px;
    margin-left: 24px;
  }

  &_CTAIcon {
    height: 16px;
    width: 18px;
    margin-right: 10px;
  }

  &_joinText {
    font-size: 16px;
    font-weight: 600;
    color: white;
  }
  &_text {
    // padding: 0 0 32px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7a8b94;
    margin-top: 8px;
  }
  &_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #3c4852;
      padding: 0 16px;
    }
    &-line {
      border: 1px solid #e5e5e5;
      height: 1px;
      width: 25%;
      margin: 0 8px;
    }
  }
  &_button {
    padding: 20px 0;
    width: 100%;
  }
}
