.usageLimit {
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &_container {
    display: flex;
    align-self: center;
    &_logo {
      width: 99px;
      height: 98px;
      align-self: center;
    }
    &_message {
      margin-top: 24px;
      &_title {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #3c4852;
      }

      &_description {
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #7a8b94;
      }
    }
  }
}
