@import "../../../../scss/main";

.ServerError {
  // height: 236px;
  // background-color: antiquewhite;
  padding-bottom: 30px;
  &__tapBlock {
    display: flex;
    justify-content: center;
    height: 20px;
    // background-color: aqua;
    &_tap {
      display: flex;
      align-self: center;
      width: 32px;
      height: 4px;
      background: #d8e0f0;
      border-radius: 4px;
    }
  }
  &_head {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #0a1629;
    margin-top: 20px;
  }
  &_description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0a1629;
    margin-top: 10px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  &_loadingImg {
    width: 145px;
    height: 90px;
    align-self: center;
  }
  &_desc {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0a1629;
    margin-top: 20px;
  }
  &_apiFail {
    // margin-top: 20px;
    display: flex;
    flex-direction: column;
    &_knot {
      // position: absolute;
      width: 19px;
      height: 4px;
      left: 0px;
      top: 0px;
      background: #2278ff;
      border-radius: 50px;
    }
    &_points {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #0a1629;
      margin-top: 10px;
    }
  }
}
.ServerErrorctaBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 24px;
  // background-color: aqua;
  &_cancelCTA {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 6px;
    width: 154px;
    height: 56px;
    // background: #2278FF;
    border: 1px solid #2278ff;
    border-radius: 16px;
    color: #2278ff;
    font-weight: bold;
  }
  &_confirmCTA {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 6px;
    width: 100%;
    height: 56px;
    background: #2278ff;
    border-radius: 16px;
    color: #ffffff;
    font-weight: bold;
  }
}
